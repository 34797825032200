<template>
  <div class="aiz-user-panel">

    <div class="aiz-titlebar mt-2 mb-4">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h1 class="h3">{{ $t('guan-li-ge-ren-zi-liao') }}</h1>
        </div>
      </div>
    </div>

    <form >
      <div class="card">
        <div class="card-header">
          <h5 class="mb-0 h6">{{ $t('ji-ben-xin-xi') }}</h5>
        </div>
        <div class="card-body">
          <div class="form-group row">
            <label class="col-md-2 col-form-label">{{ $t('ni-de-ming-zi-0') }}</label>
            <div class="col-md-10">
              <input type="text" class="form-control" :placeholder="$t('ni-de-ming-zi-0')" v-model="userData.FullName" >
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-2 col-form-label">{{ $t('ni-de-shou-ji-0') }}</label>
            <div class="col-md-10">
              <input type="text" class="form-control" :placeholder="$t('ni-de-shou-ji-0')" v-model="userData.Phone">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-2 col-form-label">{{ $t('zhao-pian') }}</label>
            <div class="col-md-10">
              <div class="input-group" data-toggle="aizuploader" data-type="image">
                <div class="input-group-prepend">
                    <div class="input-group-text bg-soft-secondary font-weight-medium">{{ $t('liu-lan') }}</div>
                </div>
                <div class="form-control file-amount" @click="addFile">{{ $t('xuan-ze-wen-jian') }}</div>
                <input type="file" class="hide-btn" id="fileInput" @change="uploadFile">
              </div>
              <div class="file-preview box sm" v-if="userData.Photo">
                <div class="d-flex justify-content-between align-items-center mt-2 file-preview-item" >
                  <div class="align-items-center align-self-stretch d-flex justify-content-center thumb">
                    <img :src="`${userData.Photo}`" class="img-fit">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row" v-show="false">
            <label class="col-md-2 col-form-label">{{ $t('ni-de-mi-ma') }}</label>
            <div class="col-md-10">
              <input type="password" class="form-control" :placeholder="$t('xin-mi-ma')" v-model="userData.Password">
            </div>
          </div>
        </div>
      </div>
<!--      收款设置 -->
      <div class="card">
        <div class="card-header">
          <h5 class="mb-0 h6">{{ $t('fu-kuan-she-zhi') }}</h5>
        </div>
        <div class="card-body">
          <div class="row" id="cash" v-show="false">
            <label class="col-md-2 col-form-label">{{ $t('xian-jin-zhi-fu') }}</label>
            <div class="col-md-9">
              <label class="aiz-switch aiz-switch-success mb-3">
                <input v-model="userData.IsCash" name="cash_on_delivery_status" type="checkbox" >
                <span class="slider round"></span>
              </label>
            </div>
          </div>

<!--          银行卡收款-->
          <template v-if="withdrawType.indexOf('bank') > -1">
            <div class="row" id="bank">
              <label class="col-md-2 col-form-label">{{ $t('yin-hang-ka') }}</label>
              <div class="col-md-9">
                <label class="aiz-switch aiz-switch-success mb-3">
                  <input v-model="userData.IsBank"  name="bank_payment_status" type="checkbox" >
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div class="row">
              <label class="col-md-2 col-form-label" for="bank_name">{{ $t('yin-hang-ming-cheng-0') }}</label>
              <div class="col-md-9">
                <input type="text" v-model="userData.Bank"   class="form-control mb-3" :placeholder="$t('yin-hang-ming-cheng-0')" required>
              </div>
            </div>
            <div class="row">
              <label class="col-md-2 col-form-label" for="bank_acc_name">{{ $t('yin-hang-zhang-hu-0') }}</label>
              <div class="col-md-9">
                <input type="text" v-model="userData.BankName"  class="form-control mb-3" :placeholder="$t('yin-hang-zhang-hu-0')" required>
              </div>
            </div>
            <div class="row">
              <label class="col-md-2 col-form-label" for="bank_acc_no">{{ $t('yin-hang-zhang-hao-0') }}</label>
              <div class="col-md-9">
                <input type="text" v-model="userData.BankNumber"  class="form-control mb-3" :placeholder="$t('yin-hang-zhang-hao-0')" required>
              </div>
            </div>
            <div class="row" v-show="false">
              <label class="col-md-3 col-form-label" for="bank_routing_no">{{ $t('yin-hang-lu-you-hao-ma-0') }}</label>
              <div class="col-md-9">
                <input type="number" v-model="userData.BankRoute"   class="form-control mb-3" :placeholder="$t('yin-hang-lu-you-hao-ma-0')" required>
              </div>
            </div>
          </template>

<!--          usdt收款方式 -->
          <template v-if="withdrawType.indexOf('usdt') > -1">
            <div class="row"  id="usdt">
              <label class="col-md-2 col-form-label">USDT</label>
              <div class="col-md-9">
                <label class="aiz-switch aiz-switch-success mb-3">
                  <input v-model="userData.IsUsdt"  name="usdt_payment_status" type="checkbox" >
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div class="row" v-show="false">
              <label class="col-md-3 col-form-label" for="usdt_type">{{ $t('usdt-wang-luo-0') }}</label>
              <div class="col-md-9">
                <input type="text" v-model="userData.UsdtNetwork" class="form-control mb-3" :placeholder="$t('usdt-wang-luo-0')">
              </div>
            </div>
            <div class="row">
              <label class="col-md-2 col-form-label" for="usdt_address">{{ $t('usdt-di-zhi-0') }}</label>
              <div class="col-md-10">
                <input type="text" v-model="userData.UsdtAddress"  class="form-control mb-3" :placeholder="$t('usdt-di-zhi-0')">
              </div>
            </div>
          </template>
          <template v-if="withdrawType.indexOf('ustd') > -1">
            <div class="row"  id="usdt">
              <label class="col-md-2 col-form-label">USTD</label>
              <div class="col-md-9">
                <label class="aiz-switch aiz-switch-success mb-3">
                  <input v-model="userData.IsUstd"  name="usdt_payment_status" type="checkbox" >
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div class="row">
              <label class="col-md-2 col-form-label" for="usdt_address">{{ $t('ustd-di-zhi') }}</label>
              <div class="col-md-10">
                <input type="text" v-model="userData.UstdAddress"  class="form-control mb-3" :placeholder="$t('ustd-di-zhi')">
              </div>
            </div>
          </template>

          <div class="form-group row">
            <label class="col-md-2 col-form-label">{{ $t('xin-mi-ma') }}</label>
            <div class="col-md-10">
              <input type="password" class="form-control" :placeholder="$t('xiugai-mima')" v-model="userData.PasswordConfirm">
            </div>
          </div>
        </div>
      </div>

      <div class="form-group mb-0 text-right">
        <button type="button" class="btn btn-primary" @click="updateUserInfo">{{ $t('geng-xin-ge-ren-xin-xi') }}</button>
      </div>
    </form>

    <br>

    <div class="card">
      <div class="card-header">
        <h5 class="mb-0 h6">{{ $t('di-zhi-0') }}</h5>
      </div>
      <div class="card-body">
        <div class="row gutters-10">
          <div class="col-lg-6" v-for="(item, i) in addressList" :key="i">
            <div class="border p-3 pr-5 rounded mb-3 position-relative">
              <div>
                <span class="w-50 fw-600">{{ $t('di-zhi-1') }}</span>
                <span class="ml-2">{{ item.Address }}</span>
              </div>
              <div>
                <span class="w-50 fw-600">{{ $t('you-zheng-bian-ma') }}</span>
                <span class="ml-2">{{ item.ZipCode }}</span>
              </div>
              <div>
                <span class="w-50 fw-600">{{ $t('cheng-shi-0') }}</span>
                <span class="ml-2">{{ item.City }}</span>
              </div>
              <div>
                <span class="w-50 fw-600">{{ $t('zhou-sheng') }}</span>
                <span class="ml-2">{{ item.Province }}</span>
              </div>
              <div>
                <span class="w-50 fw-600">{{ $t('guo-jia-0') }}</span>
                <span class="ml-2">{{ item.Country }}</span>
              </div>
              <div>
                <span class="w-50 fw-600">{{ $t('quan-ming-1') }}</span>
                <span class="ml-2">{{ item.FullName }}</span>
              </div>
              <div>
                <span class="w-50 fw-600">{{ $t('dian-hua-1') }}</span>
                <span class="ml-2">{{ item.Phone }}</span>
              </div>
              <div class="position-absolute right-0 bottom-0 pr-2 pb-3" v-if="item.IsSelected === 1">
                <span class="badge badge-inline badge-primary">{{ $t('mo-ren') }}</span>
              </div>
              <div class="dropdown position-absolute right-0 top-0">
                <Popover
                  v-model="item.showPopover"
                  trigger="click"
                  placement="bottom-end"
                  :actions="getActions(item)"
                  @select="e => doAction(e, item)"
                >
                  <template #reference>
                    <button class="btn bg-gray px-2" type="button" data-toggle="dropdown">
                      <i class="la la-ellipsis-v"></i>
                    </button>
                  </template>
                </Popover>
              </div>
            </div>
          </div>

          <div class="col-lg-6 mx-auto"  >
            <div class="border p-3 rounded mb-3 c-pointer text-center bg-light" @click="add">
              <i class="la la-plus la-2x"></i>
              <div class="alpha-7">{{ $t('tian-jia-xin-di-zhi') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <Popup v-model="showAddressPopup">
      <div class="popup-box ">
        <div class="modal-dialog modal-dialog-centered modal-dialog-zoom" role="document">
          <div class="modal-content">
            <div class="modal-header bord-btm">
              <h4 class="modal-title h6">{{modalType == 'add' ? $t('New Address') : $t('Modify Address')}}</h4>
              <button type="button" class="close" @click="cancel"><span aria-hidden="true">&times;</span></button>
            </div>
            <form class="form-horizontal" >
              <div class="modal-body">
                <div class="form-group">
                  <div class=" row">
                    <label class="col-sm-3 control-label" for="address">{{ $t('di-zhi-2') }}</label>
                    <div class="col-sm-9">
                      <textarea :placeholder="$t('di-zhi-3')" v-model="addressForm.address" name="address" class="form-control" required></textarea>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class=" row">
                    <label class="col-sm-3 control-label">{{ $t('guo-jia-1') }}</label>
                    <div class="col-sm-9">
                      <a-select class="form-control clear" size="large" v-model="addressForm.country" :placeholder="$t('qing-shu-ru-guo-jia')" show-search :filter-option="filterOption">
                        <a-select-option v-for="(item, i) in countryList" :key="i" :value="item">{{item}}</a-select-option>
                      </a-select>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-3 control-label">
                      <label>{{ $t('sheng-fen-0') }}</label>
                    </div>
                    <div class="col-sm-9">
                      <input type="text" min="0" :placeholder="$t('qing-shu-ru-sheng-fen')" v-model="addressForm.province" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-3 control-label">
                      <label>{{ $t('cheng-shi-1') }}</label>
                    </div>
                    <div class="col-sm-9">
                      <input type="text" min="0" :placeholder="$t('qing-shu-ru-cheng-shi')" v-model="addressForm.city" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class=" row">
                    <label class="col-sm-3 control-label" for="postal_code">{{ $t('you-zheng-bian-ma-0') }}</label>
                    <div class="col-sm-9">
                      <input type="number" min="0" :placeholder="$t('you-zheng-bian-ma-1')" v-model="addressForm.zipCode" name="postal_code" class="form-control" required>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class=" row">
                    <label class="col-sm-3 control-label" for="postal_code">{{ $t('quan-ming') }}</label>
                    <div class="col-sm-9">
                      <input type="text" min="0" :placeholder="$t('qing-shu-ru-quan-ming')" v-model="addressForm.fullName" name="postal_code" class="form-control" required>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class=" row">
                    <label class="col-sm-3 control-label" for="phone">{{ $t('dian-hua') }}</label>
                    <div class="col-sm-9">
                      <input type="number" min="0" :placeholder="$t('dian-hua')" v-model="addressForm.phone" name="phone" class="form-control" required>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-secondary"  @click="cancel">{{ $t('qu-xiao') }}</button>
                  <button type="button" class="btn btn-primary btn-styled btn-base-1" @click="submit">{{ $t('bao-cun') }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Popup>

  </div>
</template>
<script>
import { getAddressList, addAddress, selectAddress } from '@/api/index'
import { updateUser, userInfo, fileUpload } from '@/api/user'
import fileListBar from '@/components/fileList'
import pageBar from '@/components/page'
import { Popup, Popover } from 'vant'
export default {
  components: {
    pageBar,
    fileListBar,
    Popup,
    Popover
  },
  data() {
    return {
      showAddressPopup: false,
      modalType: '',
      pageType: '',
      form: {
        fileName: ''
      },
      addressForm: {
        city: '',
        province: '',
        country: '',
        address: '',
        zipCode: '',
        phone: '',
        fullName: '',
      },
      uploadObj:{
        Photo: ''
      },
      fileList: [],
      addressList: [],
      actions: [
        { text: this.$t('xiu-gai'), type: 'edit' },
        { text: this.$t('she-wei-mo-ren'), type: 'default' },
        { text: this.$t('shan-chu'), type: 'remove' }
      ],
      userData: {

      },
      countryList : [],
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    },
    withdrawType(){
      return this.$store.state.withdraw_type
    }
  },
  watch: {
    userInfo(val) {
      if (val.ID) {
        this.userData = Object.assign({
          Password: '',
          PasswordConfirm: ''
        }, this.userInfo)
      }
    }
  },
  mounted() {
    console.log("this.$store.state.withdraw_type:",this.$store.state.withdraw_type)
    this.countryList = this.$store.state.country
    let type = this.$route.query.type
    if (type) {
      this.pageType = type
    }

    this.init()
    this.userData = Object.assign({
      Password: '',
      PasswordConfirm: ''
    }, this.userInfo)
  },
  methods: {
    init() {
      getAddressList().then(res => {
        this.addressList = res.data.Items
      })
    },
    initUser() {
      userInfo().then(res => {
        if (res.code == 0) {
          this.$store.state.userInfo = res.data
        }
      })
    },
    addFile(type) {
      this.fileType = type
      document.getElementById('fileInput').click()
    },
    uploadFile() {
      let file = document.getElementById('fileInput').files[0]
      let form = new FormData()
      form.append('file', file)
      fileUpload(form).then(res => {
        if (res.code == 0) {
          this.userData.Photo = "/upload/tmp/"+ res.data
          this.uploadObj.Photo = res.data
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    getActions(data) {
      if (data.IsSelected === 1) {
        return this.actions.filter(v => {
          return v.type != 'default'
        })
      } else {
        return this.actions
      }
    },
    doAction(action, data) {
      let key = action.type
      if (key === 'default') {
        let form = new FormData()
        form.append('ID', data.ID)
        selectAddress(form).then(res => {
          if (res.code == 0) {
            this.$toast(this.$t('she-zhi-cheng-gong'))
            this.init()
          } else {
            this.$toast(res.msg)
          }
        })
      }
    },
    cancel() {
      this.showAddressPopup = false
    },
    add() {
      this.showAddressPopup = true
      this.modalType = 'add'
    },
    submit() {
      if (!this.addressForm.address) {
        this.$toast.fail(this.$t('qing-shu-ru-di-zhi'))
        return
      }
      if (!this.addressForm.country) {
        this.$toast.fail(this.$t('qing-shu-ru-guo-jia-0'))
        return
      }
      if (!this.addressForm.province) {
        this.$toast.fail(this.$t('qing-shu-ru-sheng-fen-0'))
        return
      }
      if (!this.addressForm.city) {
        this.$toast.fail(this.$t('qing-shu-ru-cheng-shi-0'))
        return
      }
      if (!this.addressForm.zipCode) {
        this.$toast.fail(this.$t('qing-shu-ru-you-zheng-bian-ma'))
        return
      }
      if (!this.addressForm.fullName) {
        this.$toast.fail(this.$t('qing-shu-ru-quan-ming'))
        return
      }
      if (!this.addressForm.phone) {
        this.$toast.fail(this.$t('qing-shu-ru-dian-hua'))
        return
      }
      let form = new FormData()
      form.append('FullName', this.addressForm.fullName)
      form.append('Country', this.addressForm.country)
      form.append('Province', this.addressForm.province)
      form.append('City', this.addressForm.city)
      form.append('Address', this.addressForm.address)
      form.append('ZipCode', this.addressForm.zipCode)
      form.append('Phone', this.addressForm.phone)
      addAddress(form).then(res => {
        if (res.code == 0) {
          this.$toast.success(this.$t('tian-jia-cheng-gong'))
          this.showAddressPopup = false
          this.init()
        } else {
          this.$toast.fail(res.msg)
        }
      })

    },
    updateUserInfo() {
      if (this.userData.Password && !this.userData.PasswordConfirm) {
        this.$toast(this.$t('qing-zai-ci-shu-ru-mi-ma'))
        return
      }
      let form = new FormData()
      form.append('FullName', this.userData.FullName)
      form.append('Phone', this.userData.Phone)
      if (this.uploadObj.Photo) {
        form.append('Photo', this.uploadObj.Photo)
      }
      // form.append('Photo', this.userData.Photo)
      form.append('Password', this.userData.PasswordConfirm)
      form.append('PasswordConfirm', this.userData.PasswordConfirm)
      form.append('IsCash', this.userData.IsCash ? 1: 0)
      form.append('IsBank', this.userData.IsBank ? 1: 0)
      if(this.userData.IsBank == 1) {
        if(this.userData.BankName.length == 0 || this.userData.BankNumber.length == 0 || this.userData.Bank.length == 0) {
          this.$toast.fail(this.$t('yin-hang-ka-xin-xi-bu-neng-wei-kong'))
          return
        }
      }
      form.append('BankName', this.userData.BankName)
      form.append('BankNumber', this.userData.BankNumber)
      form.append('Bank', this.userData.Bank)
      form.append('BankRoute', this.userData.BankRoute)
      form.append('UsdtAddress', this.userData.UsdtAddress)
      form.append('UstdAddress', this.userData.UstdAddress)
      form.append('IsUstd', this.userData.IsUstd ? 1: 0)
      form.append('IsUsdt', this.userData.IsUsdt ? 1: 0)
      updateUser(form).then(res => {
        if (res.code == 0) {
          this.$toast(this.$t('geng-xin-cheng-gong'))
          this.initUser()
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  }
}
</script>